import React from "react"
import PageSection from "../../components/PageSection/PageSection"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as styles from "./RoadmapSection.module.scss"

export default function RoadmapSection() {
  const data = useStaticQuery(graphql`
    query {
      bg: file(relativePath: { eq: "bg/bg_high_level_roadmap.png" }) {
        ...defaultImageQuery
      }

      dev: file(relativePath: { eq: "roadmap/milestones/roadmap_dev.png" }) {
        ...defaultImageQuery
      }

      closedBeta: file(
        relativePath: { eq: "roadmap/milestones/roadmap_closed_demo.png" }
      ) {
        ...defaultImageQuery
      }

      testNet: file(
        relativePath: { eq: "roadmap/milestones/roadmap_testnet.png" }
      ) {
        ...defaultImageQuery
      }

      sale: file(relativePath: { eq: "roadmap/milestones/roadmap_sale.png" }) {
        ...defaultImageQuery
      }

      devMobile: file(
        relativePath: { eq: "roadmap/milestones/no_ornament/roadmap_dev.png" }
      ) {
        ...defaultImageQuery
      }

      closedBetaMobile: file(
        relativePath: {
          eq: "roadmap/milestones/no_ornament/roadmap_closed_bata.png"
        }
      ) {
        ...defaultImageQuery
      }

      testMobile: file(
        relativePath: { eq: "roadmap/milestones/no_ornament/roadmap_test.png" }
      ) {
        ...defaultImageQuery
      }

      saleMobile: file(
        relativePath: { eq: "roadmap/milestones/no_ornament/roadmap_sale.png" }
      ) {
        ...defaultImageQuery
      }
    }
  `)

  return (
    <PageSection
      id="roadmap"
      classes={styles.container}
      image={data.bg}
      title="Cryptopia Roadmap"
      subTitle="2022 - 2023"
    >
      <div className={styles.content}>
        <div className={`${styles.roadmap} d-none d-lg-flex`}>
          <div className="container">
            <div className="row flex-nowrap">
              <div className="col col-lg-3">
                <div className={`${styles.milestone}`}>
                  <h3>Q3 2022</h3>
                  <h4 className={styles.q1a}>Gameplay</h4>
                  <GatsbyImage
                    className={styles.image}
                    image={getImage(data.dev)}
                    alt="Roadmap milestone 2021 Q4 - Proof of Concept"
                  />
                  <ul className={styles.q1a}>
                    <li>Player Profile</li>
                    <li>Dynamic player and creature movement</li>
                    <li>Seasteading Tiles</li>
                  </ul>
                </div>
              </div>
              <div className="col col-lg-3">
                <div className={`${styles.milestone}`}>
                  <h3>Q4 2022</h3>
                  <h4 className={styles.q1}>Closed Demo</h4>
                  <GatsbyImage
                    className={styles.image}
                    image={getImage(data.closedBeta)}
                    alt="Roadmap milestone 2022 Q1 - NFT Marketplace"
                  />
                  <ul className={styles.q1}>
                    <li>Prepare for Closed Demo Update</li>
                    <li>Mini Games</li>
                    <li>
                      Start Major Closed Demo Update<br></br> (Early Access
                      holders only)
                    </li>
                    <li>Android Build</li>
                  </ul>
                </div>
              </div>
              <div className="col col-lg-3">
                <div className={`${styles.milestone}`}>
                  <h3>Q1 2023</h3>
                  <h4 className={styles.q2}>Alpha Testnet</h4>
                  <GatsbyImage
                    className={styles.image}
                    image={getImage(data.testNet)}
                    alt="Roadmap milestone 2023 Q1 - Marketplace & Wallet"
                  />
                  <ul className={styles.q2}>
                    <li>Start Public Alpha Testnet</li>
                  </ul>
                </div>
              </div>
              <div className="col col-lg-3">
                <div className={`${styles.milestone}`}>
                  <h3>Q2 2023</h3>
                  <h4 className={styles.q3}>Token Sale</h4>
                  <GatsbyImage
                    className={styles.image}
                    image={getImage(data.sale)}
                    alt="Roadmap milestone 2022 Q3 - Gameplay"
                  />

                  <ul className={styles.q3}>
                    <li>Public Sale of Cryptopia Token (CRT)</li>
                    <li>
                      Start Public Beta Testnet Launch <br></br>(Carry resources
                      from testnet to mainnet)
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`${styles.roadmapVert} d-md-block d-lg-none`}>
          <div className="container">
            <div className="row">
              <div className="col col-lg-12">
                <div className={styles.milestone}>
                  <div className={styles.milestoneImg}>
                    <GatsbyImage
                      className={styles.image}
                      image={getImage(data.devMobile)}
                      alt="Roadmap milestone 2021 Q4 - Proof of Concept"
                    />
                  </div>
                  <div className={styles.milestoneInfo}>
                    <h3>Q3 2022</h3>
                    <h4 className={styles.q1a}>Gameplay</h4>
                    <ul className={styles.q1a}>
                      <li>Player Profile</li>
                      <li>Dynamic player and creature movement</li>
                      <li>Seasteading Tiles</li>
                    </ul>
                  </div>
                </div>

                <div className={styles.milestone}>
                  <div className={styles.milestoneImg}>
                    <GatsbyImage
                      className={styles.image}
                      image={getImage(data.closedBetaMobile)}
                      alt="Roadmap milestone 2022 Q1 - NFT Marketplace"
                    />
                  </div>
                  <div className={styles.milestoneInfo}>
                    <h3>Q4 2022</h3>
                    <h4 className={styles.q1}>Closed Demo</h4>
                    <ul className={styles.q1}>
                      <li>Prepare for Closed Demo Update</li>
                      <li>Mini Games</li>
                      <li>
                        Start Major Closed Demo Update<br></br> (Early Access
                        holders only)
                      </li>
                      <li>Android Build</li>
                    </ul>
                  </div>
                </div>

                <div className={styles.milestone}>
                  <div className={styles.milestoneImg}>
                    <GatsbyImage
                      className={styles.image}
                      image={getImage(data.testMobile)}
                      alt="Roadmap milestone 2022 Q2 - Marketplace & Wallet"
                    />
                  </div>
                  <div className={styles.milestoneInfo}>
                    <h3>Q1 2023</h3>
                    <h4 className={styles.q2}>Public Beta Testnet</h4>
                    <ul className={styles.q2}>
                      <li>
                        Start Public Beta Testnet Launch <br></br>(Carry
                        resources from testnet to mainnet)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={styles.milestone}>
                  <div className={styles.milestoneImg}>
                    <GatsbyImage
                      className={styles.image}
                      image={getImage(data.saleMobile)}
                      alt="Roadmap milestone 2022 Q3 - Gameplay"
                    />{" "}
                  </div>
                  <div className={styles.milestoneInfo}>
                    <h3>Q2 2023</h3>
                    <h4 className={styles.q3}>Token Sale</h4>
                    <ul className={styles.q3}>
                      <li>Public Sale of Cryptopia Token (CRT)</li>
                      <li>Node License NFTs</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageSection>
  )
}
