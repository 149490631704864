import React from "react"
import PageSection from "../../components/PageSection/PageSection"
import PageSectionColumns from "../../components/PageSection/PageSectionColumns"
import { useStaticQuery, graphql } from "gatsby"
import * as styles from "./CompanionIntroSection.module.scss"

export default function CompanionIntroSection() {
  const data = useStaticQuery(graphql`
    query {
      catWithOwner: file(
        relativePath: { eq: "companions/cats/pet_with_owner_round.png" }
      ) {
        ...defaultImageQuery
      }

      catWithOwnerSM: file(
        relativePath: { eq: "companions/cats/pet_with_owner_round_sm.png" }
      ) {
        ...defaultImageQuery
      }

      bg: file(relativePath: { eq: "bg/bg_pets_gradient_top.png" }) {
        ...defaultImageQuery
      }

      logo: file(relativePath: { eq: "logos/logo_cryptopia_pets_sm.png" }) {
        ...defaultImageQuery
      }
    }
  `)

  return (
    <PageSection
      id="companions"
      image={data.bg}
      classes={styles.container}
      compRef="companion-intro-section"
    >
      <PageSectionColumns
        imageTop={data.logo}
        titleTop="Companions for in the metaverse"
        title="Cryptopia Pets"
        text="There is a great affection for cats. A soft, warm-hearted cat with fur and a plump yet petite body would make each person who saw it really want to embrace and… Cats are always fun, and they’re the purrfect companions. They bring joy around the house, and their elegant look gives an already stylish home subtle but striking nuance. Mint a cat and bring them along as your companion into the metaverse! There may be special surprises for “crazy cat ladies” (and men)."
        image={data.catWithOwner}
        imageSM={data.catWithOwnerSM}
      ></PageSectionColumns>
    </PageSection>
  )
}
