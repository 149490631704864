import { Link } from "gatsby"
import React from "react"
import TeamMember from "../../components/TeamMember/TeamMember"
import * as styles from "./TeamSection.module.scss"

export default function TeamSection({ data }) {
  const founders = data.nodes.filter(tm => tm.frontmatter.group === "Founders")
  const chiefs = data.nodes.filter(tm => tm.frontmatter.group === "Chiefs")
  const managers = data.nodes.filter(tm => tm.frontmatter.group === "Managers")
  const developers = data.nodes.filter(
    tm => tm.frontmatter.group === "Developers"
  )
  const artists = data.nodes.filter(tm => tm.frontmatter.group === "Artists")

  const legalAndAdvisors = data.nodes.filter(
    tm => tm.frontmatter.group === "Legal and Advisors"
  )
  const ambassadors = data.nodes.filter(
    tm => tm.frontmatter.group === "Ambassadors"
  )
  const moderators = data.nodes.filter(
    tm => tm.frontmatter.group === "Moderators"
  )

  return (
    <div className={styles.container} id="team">
      <div className="container">
        <div className="row d-flex w-100 justify-content-center align-items-center d-flex w-100 justify-content-center align-items-center">
          <div
            className={`${styles.roleTitle} col-lg-12 col-md-12 col-12 col-sm-12 d-flex justify-content-center`}
          >
            <h1>Founders</h1>
          </div>
          {founders.map(m => {
            return (
              <div
                key={`team-member-${m.frontmatter.name}`}
                className={`${styles.teamMember} col-lg-3 col-md-5 d-flex justify-content-center`}
              >
                <Link to={"/team/" + m.frontmatter.slug}>
                  <TeamMember
                    name={m.frontmatter.name}
                    role={m.frontmatter.role}
                    bio={m.frontmatter.bio}
                    image={m.frontmatter.avatar_path}
                  />
                </Link>
              </div>
            )
          })}
        </div>

        <div className="row d-flex w-100 justify-content-center align-items-center d-flex w-100 justify-content-center align-items-center">
          <div
            className={`${styles.roleTitle} col-lg-12 col-md-12 col-12 col-sm-12 d-flex justify-content-center`}
          >
            <h1>Chiefs</h1>
          </div>
          {chiefs.reverse().map(m => {
            return (
              <div
                key={`team-member-${m.frontmatter.name}`}
                className={`${styles.teamMember} col-lg-3 col-md-5 d-flex justify-content-center`}
              >
                <Link to={"/team/" + m.frontmatter.slug}>
                  <TeamMember
                    name={m.frontmatter.name}
                    role={m.frontmatter.role}
                    bio={m.frontmatter.bio}
                    image={m.frontmatter.avatar_path}
                  />
                </Link>
              </div>
            )
          })}
        </div>

        <div className="row d-flex w-100 justify-content-center align-items-center d-flex w-100 justify-content-center align-items-center">
          <div
            className={`${styles.roleTitle} col-lg-12 col-md-12 col-12 col-sm-12 d-flex justify-content-center`}
          >
            <h1>Managers</h1>
          </div>
          {managers.map(m => {
            return (
              <div
                key={`team-member-${m.frontmatter.name}`}
                className={`${styles.teamMember} col-lg-3 col-md-5 d-flex justify-content-center`}
              >
                <Link to={"/team/" + m.frontmatter.slug}>
                  <TeamMember
                    name={m.frontmatter.name}
                    role={m.frontmatter.role}
                    bio={m.frontmatter.bio}
                    image={m.frontmatter.avatar_path}
                  />
                </Link>
              </div>
            )
          })}
        </div>

        <div className="row d-flex w-100 justify-content-center align-items-center d-flex w-100 justify-content-center align-items-center">
          <div
            className={`${styles.roleTitle} col-lg-12 col-md-12 col-12 col-sm-12 d-flex justify-content-center`}
          >
            <h1>Developers</h1>
          </div>
          {developers.map(m => {
            return (
              <div
                key={`team-member-${m.frontmatter.name}`}
                className={`${styles.teamMember} col-lg-3 col-md-5 d-flex justify-content-center`}
              >
                <Link to={"/team/" + m.frontmatter.slug}>
                  <TeamMember
                    name={m.frontmatter.name}
                    role={m.frontmatter.role}
                    bio={m.frontmatter.bio}
                    image={m.frontmatter.avatar_path}
                  />
                </Link>
              </div>
            )
          })}
        </div>

        <div className="row d-flex w-100 justify-content-center align-items-center d-flex w-100 justify-content-center align-items-center">
          <div
            className={`${styles.roleTitle} col-lg-12 col-md-12 col-12 col-sm-12 d-flex justify-content-center`}
          >
            <h1>Artists</h1>
          </div>
          {artists.map(m => {
            return (
              <div
                key={`team-member-${m.frontmatter.name}`}
                className={`${styles.teamMember} col-lg-3 col-md-5 d-flex justify-content-center`}
              >
                <Link to={"/team/" + m.frontmatter.slug}>
                  <TeamMember
                    name={m.frontmatter.name}
                    role={m.frontmatter.role}
                    bio={m.frontmatter.bio}
                    image={m.frontmatter.avatar_path}
                  />
                </Link>
              </div>
            )
          })}
        </div>

        <div className="row d-flex w-100 justify-content-center align-items-center d-flex w-100 justify-content-center align-items-center">
          <div
            className={`${styles.roleTitle} col-lg-12 col-md-12 col-12 col-sm-12 d-flex justify-content-center`}
          >
            <h1>Legal & Advisors</h1>
          </div>
          {legalAndAdvisors.map(m => {
            return (
              <div
                key={`team-member-${m.frontmatter.name}`}
                className={`${styles.teamMember} col-lg-3 col-md-5 d-flex justify-content-center`}
              >
                <Link to={"/team/" + m.frontmatter.slug}>
                  <TeamMember
                    name={m.frontmatter.name}
                    role={m.frontmatter.role}
                    bio={m.frontmatter.bio}
                    image={m.frontmatter.avatar_path}
                  />
                </Link>
              </div>
            )
          })}
        </div>

        <div className="row d-flex w-100 justify-content-center align-items-center d-flex w-100 justify-content-center align-items-center">
          <div
            className={`${styles.roleTitle} col-lg-12 col-md-12 col-12 col-sm-12 d-flex justify-content-center`}
          >
            <h1>Ambassadors</h1>
          </div>
          {ambassadors.map(m => {
            return (
              <div
                key={`team-member-${m.frontmatter.name}`}
                className={`${styles.teamMember} col-lg-3 col-md-5 d-flex justify-content-center`}
              >
                <Link to={"/team/" + m.frontmatter.slug}>
                  <TeamMember
                    name={m.frontmatter.name}
                    role={m.frontmatter.role}
                    bio={m.frontmatter.bio}
                    image={m.frontmatter.avatar_path}
                  />
                </Link>
              </div>
            )
          })}
        </div>

        <div className="row d-flex w-100 justify-content-center align-items-center d-flex w-100 justify-content-center align-items-center">
          <div
            className={`${styles.roleTitle} col-lg-12 col-md-12 col-12 col-sm-12 d-flex justify-content-center`}
          >
            <h1>Moderators</h1>
          </div>
          {moderators.map(m => {
            return (
              <div
                key={`team-member-${m.frontmatter.name}`}
                className={`${styles.teamMember} col-lg-3 col-md-5 d-flex justify-content-center`}
              >
                <Link to={"/team/" + m.frontmatter.slug}>
                  <TeamMember
                    name={m.frontmatter.name}
                    role={m.frontmatter.role}
                    bio={m.frontmatter.bio}
                    image={m.frontmatter.avatar_path}
                  />
                </Link>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
