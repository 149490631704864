import { navigate } from "gatsby"
import React, { useState } from "react"
import PageSection from "../../components/PageSection/PageSection"
import { useStaticQuery, graphql } from "gatsby"
import PageSectionColumns from "../../components/PageSection/PageSectionColumns"
import * as styles from "./RoadmapVideoSection.module.scss"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function RoadmapVideoSection() {
  const [displayVideo, setDisplayVideo] = useState(false)

  const data = useStaticQuery(graphql`
    query {
      roadmapExplainedSangho: file(
        relativePath: { eq: "youtube_thumbnails/roadmap_explained_sangho.png" }
      ) {
        ...defaultImageQuery
      }
    }
  `)

  return (
    <PageSection classes={styles.container}>
      <PageSectionColumns
        titleTop="Chieftopia Explains"
        title="Roadmap Update 2022-2023"
        buttonClass="blue border-blue"
        buttonText="View detailed version"
        onButtonClick={() =>
          navigate("https://sharing.clickup.com/24415847/b/q93k7-827/board")
        }
        imageLeft={true}
        text={`
            Our updated Roadmap is here. We would like to thank everyone for patiently waiting for the launch of Cryptopia. I am sure you all are very eager to play our game and in this update video, we share with you the latest accomplishments we have in the development of the game along with what is to expect in the future.
            <br><br>
            We sincerely appreciate your support for Cryptopia. We are working very hard to deliver a groundbreaking blockchain-driven metaverse game. A gaming experience unlike any other. See you in Cryptopia.
            `}
        content={
          <div className={styles.contentContainer}>
            {!displayVideo ? (
              <div onClick={() => setDisplayVideo(true)}>
                <GatsbyImage
                  image={getImage(data.roadmapExplainedSangho)}
                  alt="roadmap explained video"
                ></GatsbyImage>
              </div>
            ) : null}
            {displayVideo ? (
              <iframe
                src="https://www.youtube.com/embed/t2_W71KgLQE?autoplay=1"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen="fullscreen"
              ></iframe>
            ) : null}
          </div>
        }
      ></PageSectionColumns>
    </PageSection>
  )
}
